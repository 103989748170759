// example theme
import { system } from "@theme-ui/presets"

export default {
  ...system,
  styles: {
    ...system.styles,
  },
  layout: {
    header: {
      //   color: "white",
      //   backgroundColor: "black",
    },
    footer: {
      //   color: "white",
      //   backgroundColor: "black",
    },
  },
  sizes: {
    container: 960,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#000",
    modes: {
      dark: {
        text: "#fff",
        background: "#000",
        primary: "#0cf",
      },
    },
  },
  buttons: {
    primary: {
      color: 'primary',
      border: '1px solid black',
      bg: 'background',
      borderRadius: '25px',
      '&:hover': {
        bg: 'text',
      }
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'Georgia, serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
}
